import React, { useRef } from "react";
import { withRouter } from "react-router";
import { Container, Image, Stack, Row, Col } from "react-bootstrap";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth } from "../Firebase.js";
// import "../styles/style.css";
import "../scss/style.scss";

const Login = ({ history }) => {
  const usernameRef = useRef();
  const passwordRef = useRef();

  const handleLogin = (e) => {
    e.preventDefault();

    const email = usernameRef.current.value + "@tapngo.com";
    const password = passwordRef.current.value;

    signInWithEmailAndPassword(auth, email, password)
      .then((uesrCredential) => {
        const user = uesrCredential.user;
        console.log(user);
        history.push("/");
      })
      .catch((error) => {
        console.log("error: " + error.message);
      });
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      history.push("/");
    } else {
      //Do Nothing
    }
  });

  return (
    <Container
      className="login d-flex flex-column justify-content-between"
    >
    <Stack gap={3} class="container mb-5">
      <div class="align-items-center text-center">
        <Image className="" src={"/icons/logo.png"} alt={"tapngo"} rounded />
        <h2 class="login-title">Welcome!</h2>
      </div>

      <form
        class="login-form needs-validation"
        id="loginForm"
        onSubmit={handleLogin}
      >
        <h3 class="login-form__title text-center">Log In</h3>

        <div class="form-group">
          <label class="form-label" for="username">
            Username
          </label>
          <input
            type="text"
            class="form-control"
            name="username"
            id="username"
            placeholder="Enter Username"
            ref={usernameRef}
            required
          />
        </div>

        <div class="form-group">
          <label class="form-label" for="password">
            Password
          </label>
          <div class="position-relative">
            <input
              type="password"
              class="form-control form-control__password"
              name="password"
              id="password"
              ref={passwordRef}
              required
              placeholder="Enter Password"
            />
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-primary w-full w-100 py-9px mt-15px"
        >
          Log In
        </button>
      </form>

      </Stack>


      <Row>
        <Col md={{ span: 8, offset: 8 }}>{'v3.5'}
        </Col>
      </Row>
 


    </Container>
    

  );
};

export default withRouter(Login);
