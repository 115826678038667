import React, { useState, useContext } from "react";
import { Container, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../contexts/Auth";
import { db, storage } from "../Firebase.js";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import PublishIcon from '@mui/icons-material/Publish';
import ClearIcon from '@mui/icons-material/Clear';
import "../styles/addissue.css";
import Loading from "./Loading";

function AddIssue() {
  const history = useHistory();
  const [issueName, setIssueName] = useState([""]);
  const [issueDescription, setIssueDescripton] = useState([""]);
  const [imageAsFile, setImageAsFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const { userInfo } = useContext(AuthContext);

  async function createIssue(url) {
    setUploading(true);
    var date = getTodaysDate();
    var time = getTodaysTime();

    console.log(url);
    const issueData = {
      vin: issueName,
      issueDescription: issueDescription,
      status: true,
      userID: "",
      userName: "",
      lastClosed: "",
      dateClosed: "",
      time: time,
      date: date,
      currentDate: date,
      currentTime: time,
      firebaseStorageURL: url,
    };

    // await addDoc(collection(db, "autogroups", userInfo.autogroup, "issueReport"), issueData); --without doc name
    await setDoc(
      doc(
        db,
        "autogroups",
        userInfo.autogroup,
        "issueReport",
        issueName
      ),
      issueData
    );

    setUploading(false);
    history.push("/");
  }

  function uploadImage() {
    setUploading(true);
    var time = new Date();
    var hh = String(time.getHours()).padStart(2, "0");
    var mm = String(time.getMinutes()).padStart(2, "0");
    var ss = String(time.getSeconds()).padStart(2, "0");
    var ms = String(time.getMilliseconds().padStart(2, "0"));
    var filename = hh + mm + ss + ms + ".png";

    const imageRef = ref(storage, `/${userInfo.autogroup}/${filename.name}`);
    const uploadTask = uploadBytesResumable(imageRef, imageAsFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;
          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            break;  
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          createIssue(downloadURL);
        });
      }
    );
  }

  const handleImageChange = (e) => {
    e.preventDefault();
    const image = e.target.files[0];
    setImageAsFile(image);
  };

  const validityCheck = (e) => {
    e.preventDefault();

    if (imageAsFile === null || !imageAsFile.name.match(/\.(jpg|jpeg|png)$/)) {
      createIssue("");
    } else {
      uploadImage();
    }
  };

  const goBack = () => {
    history.push("/");
  };

  function getTodaysDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    return dd + "-" + mm + "-" + yyyy;
  }

  function getTodaysTime() {
    var time = new Date();
    var hh = String(time.getHours()).padStart(2, "0");
    var mm = String(time.getMinutes()).padStart(2, "0");
    return hh + ":" + mm;
  }

  if (uploading) {
    return <Loading message="Creating New Issue..." />
  }

  return (
    <Container id="add_issue_con">

      <div className="it-div center">
      <img width="64" height="64" src="/logo192.png" alt="app_logo" /> 
        <h1 className="extra-padding" id="app_name">Create Issue</h1>
      </div>
      <>
        <Form onSubmit={validityCheck}>
          <Form.Group className="mb-3">
            <Form.Label id="app_name">Issue Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Issue"
              onChange={(e) => setIssueName(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid issue name.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label id="app_name">Issue Description</Form.Label>
            <Form.Control
              required
              as="textarea"
              rows={3}
              placeholder="Describe issue"
              onChange={(e) => setIssueDescripton(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid descripton.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label id="app_name">Image Attachment</Form.Label>
            <br></br>
            <Form.Control
              type="file"
              id="single"
              onChange={handleImageChange}
            />
          </Form.Group>

          <Button id="cancel_btn" onClick={goBack}><ClearIcon /> Cancel</Button>
          <Button id="submit_btn" variant="success" type="submit">
            <PublishIcon /> Create Issue
          </Button>
        </Form>
      </>
    </Container>
  );
}

export default AddIssue;
