import React, { useContext, useEffect, useState } from "react";
import { Container, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../contexts/Auth";
import { db } from "../Firebase.js";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";


import { ExcelRenderer } from "react-excel-renderer";

import { DoubleBubble } from "react-spinner-animated";
import "react-spinner-animated/dist/index.css";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView, TabPanel } from "primereact/tabview";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../styles/count.css";
import "../styles/exceptions.css";

import "../components/SearchInput";
import useSearchInput from "../components/SearchInput";

import infoIcon from "../images/icons/icon-info.svg";
import searchIcon from "../images/icons/icon-search-primary.svg";
import downloadIcon from "../images/icons/icon-upload-primary.svg";

function Exceptions() {
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);

  const [fetchDB, setFetchDB] = useState(true);
  const [loadingText, setLoadingText] = useState("");
  const [lots, setLots] = useState([]);

  const [vins, setVins] = useState([]);
  const [location, setLocation] = useState("");
  const [summaryResults, setSummaryResults] = useState("");
  const [subResults, setSubResults] = useState("");

  const [unders, setUnders] = useState([]);
  const [overs, setOvers] = useState([]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [DMSfileName, setDMSfileName] = useState("");

  const [DMS_headers, setDMS_headers] = useState([]);
  const [DMS_vins, setDMS_vins] = useState([]);

  const [showEntryDivs, setEntryDivs] = useState(true);
  const [showCalcDiv, setShowCalcDiv] = useState(false);
  const [showResults, setShowResult] = useState(false);

  const [globalFilter, setGlobalFilter] = useState(null);

  const countCols = [
    { field: "vin", header: "Vin" },
    { field: "entry", header: "Entry" },
    { field: "date", header: "Date" },
    { field: "time", header: "Time" },
    { field: "userID", header: "User" },
    { field: "locOrigin", header: "Location" },
  ];

  const countColumns = countCols.map((col, i) => {
    return <Column key={col.field} field={col.field} header={col.header} />;
  });

  useEffect(() => {
    setFetchDB(true);
    if (userInfo !== undefined) {
      getLots();
    }
    if (vins && vins.length) {
      calculateExceptions();
    }
  }, [userInfo, vins]);

  async function getLots() {
    const lotItems = [];

    const docRef = collection(db, "orgs", userInfo.org, "lots");
    const querySnapshot = await getDocs(docRef);

    querySnapshot.forEach((doc) => {
      lotItems.push(doc.data());
    });
    // console.log(lotItems);
    setFetchDB(false);
    setLots(lotItems);
  }

  const handleLocation = (loc) => {
    console.log("setting location ---" + loc);
    setLocation(loc);
  };

  if (fetchDB) {
    return (
      <DoubleBubble
        text={loadingText}
        center={true}
        width={"150px"}
        height={"150px"}
      />
    );
  }

  const fileHandler = (event) => {
    let fileObj = event.target.files[0];
    var excelItems = [];
    var headers = [];

    setDMSfileName(fileObj.name);
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        //Col Headers
        const temparr = resp.rows.at(4);
        for (var i = 0; i < temparr.length; i++) {
          let col = {
            header: temparr[i],
            field: temparr[i],
          };
          headers.push(col);
        }

        //VINS
        for (var j = 5; j < resp.rows.length - 1; j++) {
          excelItems.push(resp.rows.at(j));
        }

        //Clean Excel
        excelItems = excelItems.filter((e) => e.length > 2);
        excelItems = createObjectArr(headers, excelItems);

        //Clean Headers
        headers = cleanExcelHeaders(headers);

        // console.log("Testing Headers---------------");
        // console.log(headers);
        // console.log("Vehicles---------");
        // console.log(excelItems);

        setDMS_headers(headers);
        setDMS_vins(excelItems);
        // console.log("new vins batch: " + DMS_vins);
        setShowCalcDiv(true);
      }
    });
  };

  function cleanExcelHeaders(propNames) {
    propNames.forEach(function (col) {
      if (typeof col.header === "undefined" || col.field === "undefined") {
        col.header = "";
        col.field = "";
      }
    });
    propNames.splice(0, 1);
    propNames.splice(5, 4);
    console.log("cutting headers ----");
    console.log(propNames);

    return propNames;
  }

  //Change based on DMS excel
  function createObjectArr(propNames, values) {
    // console.log("Testing Props---------------");
    // console.log(propNames);

    const json = values.map(function (vehicle) {
      return {
        "Last 6 of VIN": vehicle[1],
        "Unit#": vehicle[2],
        Make: vehicle[3],
        Model: vehicle[4],
        Year: vehicle[5],
        Cost: vehicle[11],
        vin: vehicle[1],
      };
    });
    return json;
  }

  const dt_DMSheaders = DMS_headers.map((col, i) => {
    console.log(col.field);

    return <Column key={col.field} field={col.field} header={col.header} />;
  });

  async function hasCountReport() {
    setFetchDB(true);

    const docRef = doc(db, "orgs", userInfo.org, "lots", location);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      if (docSnap.data().countCompleted) {
        console.log("doc exist");
        getLocationReport();
      } else {
        console.log("doc not exist!");
        setFetchDB(false);
      }
    } else {
      console.log("no doc exists!");
      setFetchDB(false);
    }
  }

  async function getLocationReport() {
    const countItems = [];

    const vinsRef = collection(
      db,
      "orgs",
      userInfo.org,
      "lots",
      location,
      "countReport"
    );

    console.log(location);
    const querySnapshot = await getDocs(vinsRef);
    querySnapshot.forEach((doc) => {
      countItems.push(doc.data());
    });
    setVins(countItems);
  }

  function calculateExceptions() {
    const filtered_overs = vins.filter(function (vehicle) {
      return !DMS_vins.some(function (excelVin) {
        return vehicle.vin === excelVin.vin.toString();
      });
    });

    // console.log("OVERS Vins:");
    // console.log(filtered_overs);

    const filtered_unders = DMS_vins.filter(function (excelVin) {
      return !vins.some(function (vehicle) {
        return excelVin.vin.toString() === vehicle.vin;
      });
    });

    setOvers(filtered_overs);
    setUnders(filtered_unders);

    writeExceptionsReport(filtered_overs, filtered_unders);
    createSummary(
      DMS_vins.length - filtered_unders.length,
      DMS_vins.length,
      filtered_unders.length,
      filtered_overs.length
    );
    setEntryDivs(false);
    setShowCalcDiv(false);
    setShowResult(true);
    // setFetchDB(false);
  }

  function createSummary(sum, total, undersSize, oversSize) {
    var percentage = ((sum / total) * 100).toFixed(2) + "%";
    setSummaryResults(
      percentage + " successfully scanned ( " + sum + " / " + total + " )"
    );
    setSubResults(undersSize + " Unders - " + oversSize + " Overs");
  }

  function getTodaysDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    return dd + "-" + mm + "-" + yyyy;
  }

  function getTodaysTime() {
    var time = new Date();
    var hh = String(time.getHours()).padStart(2, "0");
    var mm = String(time.getMinutes()).padStart(2, "0");
    return hh + ":" + mm;
  }

  async function writeExceptionsReport(overs_arr, unders_arr) {
    const orgsExceptionReportRef = collection(
      db,
      "orgs",
      userInfo.org,
      "exceptionsReport"
    );

    const oversSize = overs_arr.length;
    const undersSize = unders_arr.length;
    const totalSize = oversSize + undersSize;

    const except_arr = [];
    const batch = writeBatch(db);

    overs_arr.forEach(function (overItem) {
      var orgsExceptionOverRef = doc(
        db,
        "orgs",
        userInfo.org,
        "exceptionsReport",
        overItem.vin
      );

      var exceptionOver = {
        vin: overItem.vin,
        locationOver: overItem.locOrigin,
        locationUnder: "",
        user: overItem.userID,
        isOver: true,
        isUnder: false,
        entry: overItem.entry,
      };

      except_arr.push(exceptionOver);

      batch.set(orgsExceptionOverRef, exceptionOver, { merge: true });
    });
    // console.log("under vin: " + unders_arr);
    unders_arr.forEach(function (underItem) {
      // console.log(underItem);

      if (underItem.vin.length > 0) {
        var orgsExceptionUnderRef = doc(
          db,
          "orgs",
          userInfo.org,
          "exceptionsReport",
          underItem.vin
        );

        var exceptionUnder = {
          vin: underItem.vin,
          locationOver: "",
          locationUnder: location,
          user: userInfo.name,
          isOver: false,
          isUnder: true,
          year: underItem.Year || "",
          make: underItem.Make || "",
          model: underItem.Model || "",
          cost: underItem.Cost || 0,
        };

        except_arr.push(exceptionUnder);

        batch.set(orgsExceptionUnderRef, exceptionUnder, { merge: true });
      }
    });

    const time = getTodaysTime();
    const date = getTodaysDate();

    console.log("Exception Array");
    // console.log(except_arr);

    const locationDetailRef = doc(db, "orgs", userInfo.org, "lots", location);
    batch.update(locationDetailRef, { exceptionCompleted: true });

    const orgDetailsRef = doc(db, "orgs", userInfo.org);

    batch.update(orgDetailsRef, {
      except_num_of_overs: oversSize,
      except_num_of_unders: undersSize,
      except_num_of_total: totalSize,
      except_time_updated: time,
      except_date_updated: date,
      exceptionCompleted: true,
    });

    await batch.commit().then(function () {
      setFetchDB(false);
      console.log("Done! - Check Database");
    });
  }

  if (fetchDB) {
    return (
      <DoubleBubble
        text={"Loading..."}
        center={true}
        width={"150px"}
        height={"150px"}
      />
    );
  }

  function refreshExceptions() {
    window.location.reload(false);
  }

  function exportPdf(arr, headers) {
    const exportColumns = headers.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable({
          columns: exportColumns,
          body: arr,
          margin: { top: 15 },
        });
        doc.save(location + "_Count_" + ".pdf");
      });
    });
  }

  function exportExcel(arr) {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(arr);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "VINs");
    });
  }

  function saveAsExcelFile(buffer, fileName) {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(data, fileName + "_" + EXCEL_EXTENSION);
    });
  }

  const SelectLocationDiv = () => (
    <div id="selectLocation" className="mb-3">
      <Card id="locationCard" className="exceptCards">
        <Card.Title>
          Choose a Location
          <p class="report-subtext">Location Selected: {location}</p>
        </Card.Title>
        <Card.Body class="col-md-3 mt-4 d-flex justify-content-betweend-flex justify-content-between">
          {lots.map((lot) => (
            <button
              disabled={!lot.countCompleted}
              type="button"
              class="btn btn-primary py-9px btn-exceptions"
              onClick={() => handleLocation(lot.name)}
            >
              {lot.abbreviation}
            </button>
          ))}

          {/* <Form.Select onChange={(e) => setLocation(e.target.value)}>
            {lots.map((lot) => (
              <option key={lot.name} value={lot.name}>
                {lot.name}
              </option>
            ))}
          </Form.Select> */}
        </Card.Body>
      </Card>
    </div>
  );

  // const InputFileDivTester = () => {

  // };

  const InputFileDiv = () => (
    <div id="inputFile" className="mb-3">
      <Card id="inputFileCard" className="exceptCards">
        <Card.Title>Input a DMS File</Card.Title>
        <Card.Body>
          <div class="upload-area">
            <h3 class="upload-area__title">Upload DMS Report</h3>
            <p class="upload-area__subtitle">
              <span>
                Drag or Click "Choose File" to upload an Excel file of your DMS
                report.
              </span>
              {/* <a href="pdf/excel-guidelines-for-exceptions-feature.pdf" target="_blank">
                            <img src={infoIcon}/>
                        </a> */}
            </p>
            <input
              type="file"
              onChange={fileHandler}
              id="inputDMSfile"
              className="d-none"
            />
            <label
              for="inputDMSfile"
              class="btn btn-primary w-full w-100 py-9px"
              style={{ maxWidth: 235 }}
            >
              Choose File
            </label>

            <small class="upload-area__small d-block">
              Supported files: .xls or .xlsx
            </small>
          </div>
        </Card.Body>
      </Card>
    </div>
  );

  const CalcButtonDiv = () => (
    <div id="calcButton d-flex flex-column" className="mb-3">
      <Card id="calcButtonCard" className="exceptCards">
        <Card.Title>Calculate Exceptions Report</Card.Title>
        <Card.Body className="center">
          <>
            <div>
              <img src="../icons/icon-location.svg"  alt="" />
              <div class="d-flex align-items-center justify-content-center">
                <h4 class="support-card__subtitle mb-2">
                  Location: {location}{" "}
                </h4>
              </div>
            </div>

            <div>
              <img src="../icons/icon-report.svg" alt="" />
              <div class="d-flex align-items-center justify-content-center">
                <h4 class="support-card__subtitle mb-2">
                  DMS File: {DMSfileName}
                </h4>
              </div>
            </div>
          </>

          <button
            type="button"
            class="btn btn-primary w-full w-50 py-9px"
            onClick={hasCountReport}
          >
            Cacluate Exceptions
          </button>

          {/* <Button onClick={hasCountReport}>Cacluate Exceptions</Button> */}
        </Card.Body>
      </Card>
    </div>
  );

  const underHeader = (
    <>
      <div
        class="d-flex justify-content-md-between justify-content-center 
        algin-items-center flex-wrap text-center text-md-left"
        style={{ marginTop: 1.75, marginBottom: 1.75 }}
      >
        <div class="d-flex flex-column">
          <h2 class="report-title text-left mb-2">Unders Report</h2>
          <p class="report-subtext">Report showing the missing vehicle from the Count Report.</p>
          <p class="report-subtext">Total Unders: {unders.length}</p>
        </div>
      </div>

      <div class="d-flex mt-3 mt-md-0" style={{ gap: 10 }}>
        <button
          type="button"
          className="btn btn-secondary w-full py-9px d-flex align-items-center"
          style={{ maxWidth: 100, gap: 10 }}
          onClick={() => {
            exportExcel(unders);
          }}
        >
          <img src={downloadIcon} alt="" />
          <span>EXCEL</span>
        </button>

        <button
          type="button"
          icon="pi pi-file-excel"
          className="btn btn-secondary w-full py-9px d-flex align-items-center"
          style={{ maxWidth: 100, gap: 10 }}
          onClick={() => {
            exportPdf(unders, DMS_headers);
          }}
        >
          <img src={downloadIcon} alt="" />
          <span>PDF</span>
        </button>
      </div>
    </>
  );

  const oversHeader = (
    <>
      <div
        class="d-flex justify-content-md-between justify-content-center algin-items-center flex-wrap text-center text-md-left"
        style={{ marginTop: 1.75, marginBottom: 1.75 }}
      >
        <div class="d-flex flex-column">
          <h2 class="report-title text-left mb-2">Overs Report</h2>
          <p class="report-subtext">
            {" "}
            Report showing Vehicles scanned in the Count Report but are not
            present in the DMS Report.
          </p>
          <p class="report-subtext">Total Overs: {overs.length}</p>
        </div>
      </div>

      <div class="d-flex mt-3 mt-md-0" style={{ gap: 10 }}>
        <button
          type="button"
          className="btn btn-secondary w-full py-9px d-flex align-items-center"
          style={{ maxWidth: 100, gap: 10 }}
          onClick={() => {
            exportExcel(overs);
          }}
        >
          <img src={downloadIcon} alt="" />
          <span>EXCEL</span>
        </button>

        <button
          type="button"
          icon="pi pi-file-excel"
          className="btn btn-secondary w-full py-9px d-flex align-items-center"
          style={{ maxWidth: 100, gap: 10 }}
          onClick={() => {
            exportPdf(overs, countCols);
          }}
        >
          <img src={downloadIcon} alt="" />
          <span>PDF</span>
        </button>
      </div>
    </>
  );

  const countHeader = (
    <>
    <div
      class="d-flex justify-content-md-between justify-content-center 
        algin-items-center flex-wrap text-center text-md-left"
      style={{ marginTop: 1.75, marginBottom: 1.75 }}
    >
      <div class="d-flex flex-column">
        <h2 class="report-title text-left mb-2">Count Report</h2>
        <h6 class="report-subtext"> Report showing the count report for {location}</h6>
        <p class="report-subtext">Total Count: {vins.length}</p>
      </div>
    </div>

    <div class="d-flex mt-3 mt-md-0" style={{ gap: 10 }}>
      <button
        type="button"
        className="btn btn-secondary w-full py-9px d-flex align-items-center"
        style={{ maxWidth: 100, gap: 10 }}
        onClick={() => {
          exportExcel(vins);
        }}
      >
        <img src={downloadIcon} alt="" />
        <span>EXCEL</span>
      </button>

      <button
        type="button"
        icon="pi pi-file-excel"
        className="btn btn-secondary w-full py-9px d-flex align-items-center"
        style={{ maxWidth: 100, gap: 10 }}
        onClick={() => {
          exportPdf(vins, countCols);
        }}
      >
        <img src={downloadIcon} alt="" />
        <span>PDF</span>
      </button>
    </div>
  </>
  ); 

  const ResultTablesDiv = () => (
    <div id="resultTables">
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Overs">
          <DataTable
            id="oversTable"
            className="exceptTables"
            value={overs}
            paginator
            rows={25}
            header={oversHeader}
            globalFilter={globalFilter}
            emptyMessage="No Overs Founds"
          >
            {countColumns}
          </DataTable>
        </TabPanel>

        <TabPanel header="Unders">
          <DataTable
            id="undersTable"
            className="exceptTables"
            value={unders}
            paginator
            rows={25}
            header={underHeader}
            globalFilter={globalFilter}
            emptyMessage="No unders Founds"
          >
            {dt_DMSheaders}
          </DataTable>
        </TabPanel>

        <TabPanel header="Count Report">
          <DataTable
            id="countTable"
            className="exceptTables"
            value={vins}
            paginator
            rows={25}
            header={countHeader}
            globalFilter={globalFilter}
          >
            {countColumns}
          </DataTable>
        </TabPanel>
      </TabView>
    </div>
  );

  const exceptionsHeader = (
    <>
      <div class="d-flex flex-column marginCover">
        <h2 class="report-title text-left mb-2">Exceptions</h2>
        <p class="report-subtext">
          Select an Autogroup from the dropdown and input a DMS file to compare
        </p>
      </div>
    </>
  );

  return (
    <Container>
      {exceptionsHeader}

      {showEntryDivs ? <SelectLocationDiv /> : null}

      {showEntryDivs ? <InputFileDiv /> : null}

      {showCalcDiv ? <CalcButtonDiv /> : null}

      {showResults ? <ResultTablesDiv /> : null}
    </Container>
  );
}

export default Exceptions;
