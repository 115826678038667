import React, { useEffect, useState, useContext } from "react";
import { getDoc, doc } from "firebase/firestore";
import {
  Container,
  Image,
  Card,
  CardGroup,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { db } from "../Firebase.js";
import { AuthContext } from "../contexts/Auth";
import { signOut } from "firebase/auth";

import { DoubleBubble } from "react-spinner-animated";
import "react-spinner-animated/dist/index.css";

import "../styles/home.css";
import "../scss/style.scss";

import Loading from "./Loading.js";

const Home = () => {
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);

  const [fetchDB, setFetchDB] = useState(true);

  const [orgDetails, setOrgDetails] = useState([]);

  const [reminderDays, setReminderDays] = useState(0);

  useEffect(() => {
    setFetchDB(true);
    if (userInfo !== undefined) {
      getDBInfo(userInfo);
    }
  }, [userInfo]);

  function handleClick(path) {
    history.push(path);
  }

  async function getDBInfo(user) {
    const items = []; 

    const docRef = doc(db, "orgs", user.org);
    const docSnap = await getDoc(docRef); 

    if(docSnap.exists()) {
      setOrgDetails(docSnap.data());
      setFetchDB(false);
    } else {
      setFetchDB(false); 
    }

    console.log(user.org);
  }


  function setReminder() {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

    const dateArr = orgDetails.pc_date_deleted.split("-");
    const firstDate =  new Date(dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0])
    const secondDate = new Date();
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

    return diffDays - 1;
  }

  if (fetchDB) {
    return (
      <DoubleBubble
        text={"Loading..."}
        center={true}
        width={"150px"}
        height={"150px"}
      />
    );
  }

  return (
    <Container fluid>
      <div className="it-div center">
        <Image
          src="/logo192.png"
          alt={"tapngo"}
          width="96"
          height="96"
          rounded
        />

        <h3> Welcome, {userInfo.org} </h3>
      </div>

      <CardGroup className="cards align-items-center justify-content-center text-center">
        <Card className="cards">
          <Card.Img variant="top" src="icons/icon-counting.svg" class="card-img" />
          <Card.Body className="d-flex flex-column">
            <Card.Title>Reports</Card.Title>
            <Card.Text>
              View the status of reports from each location.
            </Card.Text>
            <button type="button" class="btn btn-primary w-full w-100 py-9px"
             onClick={() => handleClick("reports")}>View Reports</button>

          </Card.Body>
          <Card.Footer>
            <small className="card-date">Last Updated: {orgDetails.pc_date_updated} - {orgDetails.pc_time_updated}</small>
          </Card.Footer>
        </Card>
        <Card className="cards">
          <Card.Img variant="top" src="icons/icon-list.svg" class="card-img" />
          <Card.Body className="d-flex flex-column">
            <Card.Title>Counts</Card.Title>
            <Card.Text>See the consolidated report of the Autogroup.</Card.Text>
            <button type="button" class="btn btn-primary w-full w-100 py-9px"
             onClick={() => handleClick("count")}>View Count</button>

          </Card.Body>

          <Card.Footer>
          <span class="card-date">Last Cleared On: {orgDetails.pc_date_deleted}</span>

            {/* <small className="text-muted">Last Cleared On: {orgDetails.pc_date_deleted}</small> */}
          </Card.Footer>
        </Card>
        <Card className="cards">
          <Card.Img variant="top" src="icons/icon-report.svg" class="card-img" />
          <Card.Body className="d-flex flex-column">
            <Card.Title>Exceptions</Card.Title>
            <Card.Text>Create an overs/unders report of a location.</Card.Text>

            <button type="button" class="btn btn-primary w-full w-100 py-9px"
            onClick={() => handleClick("exceptions")}>Create Report</button>

          </Card.Body>
          <Card.Footer>
          <span class="card-date"> Last Updated On: {orgDetails.except_date_updated}  -  {orgDetails.except_time_updated}</span>
            {/* <small className="text-muted">Last Updated On: {orgDetails.except_date_updated} at {orgDetails.except_time_updated}</small> */}
          </Card.Footer>
        </Card>

{/* INVENTORY TEKION
        <Card className="cards">
          <Card.Img variant="top" src="icons/icon-report.svg" class="card-img" />
          <Card.Body className="d-flex flex-column">
            <Card.Title>Inventory (Beta)</Card.Title>
            <Card.Text>View Inventory from the DMS</Card.Text>

            <button type="button" class="btn btn-primary w-full w-100 py-9px"
            onClick={() => handleClick("inventory")}>View Inventory</button>

          </Card.Body>
          <Card.Footer>
          <span class="card-date"> Last Updated On: {orgDetails.except_date_updated}  -  {orgDetails.except_time_updated}</span>
            {<small className="text-muted">Last Updated On: {orgDetails.except_date_updated} at {orgDetails.except_time_updated}</small>}
          </Card.Footer>
        </Card>
 */}        
      </CardGroup>

      <div class="remind">
        <p>It has been { setReminder()} day(s) since Reports were last cleared.</p>
      </div>
    </Container>
  );
};

export default Home;
