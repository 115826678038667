import React, { useEffect, useState, useContext } from "react";
import axios from "axios"
import { Container, Row, Modal, Stack, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { db } from "../Firebase.js";
import { AuthContext } from "../contexts/Auth";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import { DoubleBubble } from "react-spinner-animated";
import "react-spinner-animated/dist/index.css";
import TekionAPI from "./TekionAPI.js";


function Inventory() {
    return ( 
        <>
        <Container>
            <TekionAPI/>
        </Container>
        </>
     );
}

export default Inventory;