import React, { useEffect, useState, useContext } from "react";
import { Container, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { db } from "../Firebase.js";
import {
  getDocs,
  collection,
  query,
  limit,
  writeBatch,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { AuthContext } from "../contexts/Auth";
// import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";

import { Column } from "primereact/column";
import { TabView, TabPanel } from "primereact/tabview";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import { FcOk } from "react-icons/fc";

import { DoubleBubble } from "react-spinner-animated";
import "react-spinner-animated/dist/index.css";

import "../styles/count.css";

import searchIcon from "../images/icons/icon-search-primary.svg";
import downloadIcon from "../images/icons/icon-upload-primary.svg";

function Count() {
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);

  const [fetchDB, setFetchDB] = useState(true);
  const [loadingText, setLoadingText] = useState("");

  const [lots, setLots] = useState([]);
  const [exceptVins, setExceptVins] = useState([]);
  const [exceptDetails, setExceptDetails] = useState([]);

  const [globalFilter, setGlobalFilter] = useState(null);

  const [activeIndex, setActiveIndex] = useState(0);

  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const handleDeleteWarningClose = () => setShowDeleteWarning(false);

  const [showExceptDeleteWarning, setShowExceptDeleteWarning] = useState(false);
  const handleExceptDeleteWarningClose = () =>
    setShowExceptDeleteWarning(false);

  var today = new Date();
  const date =
    today.getFullYear() + "_" + (today.getMonth() + 1) + "_" + today.getDate();

  const cols = [
    { field: "vin", header: "Vin" },
    { field: "entry", header: "Entry" },
    { field: "date", header: "Date" },
    { field: "time", header: "Time" },
    { field: "userID", header: "User" },
    { field: "locOrigin", header: "Location" },
  ];

  // const exceptCols = [
  //   { field: "vin", header: "Vin" },
  //   { field: "user", header: "User" },
  //   { field: "locationOver", header: "Location Over" },
  //   { field: "locationUnder", header: "Location Under" },
  //   { field: "isOver", header: "Over" },
  //   { field: "isUnder", header: "Under" },
  // ];

  // const exceptColumns = exceptCols.map((col, i) => {
  //   return <Column key={col.field} field={col.field} header={col.header} />;
  // });

  const countCols = cols.map((col, i) => {
    return <Column key={col.field} field={col.field} header={col.header} />;
  });

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  useEffect(() => {
    setFetchDB(true);
    if (userInfo !== undefined) {
      getReports();
      getExceptionReport();
    }
  }, [userInfo]);

  async function getReports() {
    const items = [];

    const docRef = collection(db, "orgs", userInfo.org, "lotsReport");
    const querySnapshot = await getDocs(docRef);

    querySnapshot.forEach((doc) => {
      console.log("cars----");
      console.log(doc.data());
      items.push(doc.data());
    });
    console.log(items);
    setFetchDB(false);

    setLots(items);
  }

  async function getExceptionReport() {
    const items = [];

    const docRef = collection(db, "orgs", userInfo.org, "exceptionsReport");
    const querySnapshot = await getDocs(docRef);

    querySnapshot.forEach((doc) => {
      items.push(doc.data());
    });

    const exceptDocRef = doc(db, "orgs", userInfo.org);
    const exceptDocSnap = await getDoc(exceptDocRef);

    if (exceptDocSnap.exists()) {
      setExceptDetails(exceptDocSnap.data());
    }

    setFetchDB(false);
    setExceptVins(items);
  }

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, lots);
        doc.save("Conslidated_Count_" + date + ".pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(lots);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "VINs");
    });
  };

  const exportExceptPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, exceptVins);
        doc.save("Exception_Report_" + date + ".pdf");
      });
    });
  };

  const exportExceptExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(exceptVins);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "VINs");
    });
  };

  function saveAsExcelFile(buffer, fileName) {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(data, fileName + "_" + date + EXCEL_EXTENSION);
    });
  }

  function getTodaysDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    return dd + "-" + mm + "-" + yyyy;
  }

  function getTodaysTime() {
    var time = new Date();
    var hh = String(time.getHours()).padStart(2, "0");
    var mm = String(time.getMinutes()).padStart(2, "0");
    return hh + ":" + mm;
  }

  function setupDeleteOrgCount() {
    const collRef = collection(db, "orgs", userInfo.org, "lotsReport");
    const q = query(collRef, limit(lots.size));
    const time = getTodaysTime();
    const date = getTodaysDate();

    setLoadingText("Deleting Count... This may take a few minutes...");
    setFetchDB(true);
    return new Promise((resolve) => {
      deleteCountCollection(db, q, lots.size, resolve).then(function () {
        const orgDetailsRef = doc(db, "orgs", userInfo.org);
        updateDoc(orgDetailsRef, {
          pc_num_of_count: 0,
          pc_num_of_manual: 0,
          pc_num_of_scanned: 0,
          pc_date_deleted: date,
          countCompleted: false,
          pc_date_updated: date,
          pc_time_updated: time,
        }).then(function () {
          setFetchDB(false);
          history.go(0);
        });
      });
    });
  }

  function setupDeleteExceptionCount() {
    const collRef = collection(db, "orgs", userInfo.org, "exceptionsReport");
    const q = query(collRef, limit(exceptVins.size));
    const time = getTodaysTime();
    const date = getTodaysDate();

    setLoadingText(
      "Deleting Exception Report... This may take a few minutes..."
    );
    setFetchDB(true);

    return new Promise((resolve) => {
      deleteCountCollection(db, q, exceptVins.size, resolve).then(function () {
        const orgDetailsRef = doc(db, "orgs", userInfo.org);
        updateDoc(orgDetailsRef, {
          except_date_updated: date,
          except_time_updated: time,
          except_num_of_overs: 0,
          except_num_of_total: 0,
          except_num_of_unders: 0,
          exceptionCompleted: false,
        }).then(function () {
          setFetchDB(false);
          history.go(0);
        });
      });
    });
  }

  //Delete Collection
  async function deleteCountCollection(db, query, batchSize, resolve) {
    const snapshot = await getDocs(query);

    let numDeleted = 0;
    if (snapshot.size > 0) {
      // Delete documents in a batch
      console.log("deleting VIN");
      const batch = writeBatch(db);
      snapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
      numDeleted = snapshot.size;
    }

    if (numDeleted < batchSize) {
      resolve();
      return;
    }

    // Recurse on the next process tick, to avoid
    // exploding the stack.
    setTimeout(() => {
      deleteCountCollection(db, query, batchSize, resolve);
    }, 0);
  }
  //End Delete Collection

  const showDeleteModal = () => {
    setShowDeleteWarning(true);
  };

  if (showDeleteWarning) {
    return (
      <Modal
        size="lg"
        show={showDeleteWarning}
        onHide={handleDeleteWarningClose}
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Count for {userInfo.org}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this month's count. This will not
          delete VINs that are in the indivdual Lot Counts.
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary w-full py-9px"
            style={{ minWidth: 313 }}
            onClick={handleDeleteWarningClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger w-full py-9px"
            style={{ minWidth: 313 }}
            onClick={setupDeleteOrgCount}
          >
            Delete Count
          </button>
          {/* <Button variant="secondary" onClick={handleDeleteWarningClose}>
            Cancel
          </Button>
          <Button className="p-button-danger" onClick={setupDeleteOrgCount}>
            Delete Count
          </Button> */}
        </Modal.Footer>
      </Modal>
    );
  }

  const showExceptDeleteModal = () => {
    setShowExceptDeleteWarning(true);
  };

  if (showExceptDeleteWarning) {
    return (
      <Modal
        size="lg"
        show={handleExceptDeleteWarningClose}
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Over/Unders for {userInfo.org}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this month's Exception Report. This
          action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleExceptDeleteWarningClose}>
            Cancel
          </Button>
          <Button
            className="p-button-danger"
            onClick={setupDeleteExceptionCount}
          >
            Delete Exceptions
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  // const vinTables = () => {
  //   <div id="countTables">
  //     <TabView
  //       activeIndex={activeIndex}
  //       onTabChange={(e) => setActiveIndex(e.index)}
  //     >
  //       <TabPanel header="Count"></TabPanel>
  //       <TabPanel header="Exception"></TabPanel>
  //     </TabView>
  //   </div>;
  // };

  const showIsOver = (rowData) => {
    const isOver = rowData.isOver;

    if (isOver) {
      return <FcOk />;
    } else {
      return;
    }
  };

  const showIsUnder = (rowData) => {
    const isUnder = rowData.isUnder;

    if (isUnder) {
      return <FcOk />;
    } else {
      return;
    }
  };

  const emptyCountMessage = () => {
    if (lots.length === 0) {
      return (
        <div className="md-4 text-center">
          <h4>Report is Empty</h4>
          <button
            type="button"
            className="btn btn-secondary w-full py-9px"
            style={{ minWidth: 313 }}
            onClick={() => history.push("/")}
          >
            Home
          </button>
        </div>
      );
    } else {
      return (
        <div className="md-4 text-center">
          <h4>No Results Found</h4>
        </div>
      );
    }
  };

  const emptyExceptionsMessage = () => {
    if (exceptVins.length === 0) {
      return (
        <div className="md-4 text-center">
          <h4>Report is Empty</h4>
          <button
            type="button"
            className="btn btn-secondary w-full py-9px"
            style={{ minWidth: 313 }}
            onClick={() => history.push("/")}
          >
            Home
          </button>
        </div>
      );
    } else {
      return (
        <div className="md-4 text-center">
          <h4>No Results Found</h4>
        </div>
      );
    }
  };

  if (fetchDB) {
    return (
      <DoubleBubble
        text={loadingText}
        center={true}
        width={"150px"}
        height={"150px"}
      />
    );
  }

  const countHeader = (
    <>
      <div
        class="d-flex justify-content-md-between justify-content-center 
        algin-items-center flex-wrap text-center text-md-left"
        style={{ marginTop: 1.75, marginBottom: 1.75 }}
      >
        <div class="d-flex flex-column">
          <h2 class="report-title text-left mb-2">{userInfo.org} Count</h2>
          <p class="report-subtext b-subtext">Total: {lots.length}</p>
        </div>

        <div class="d-flex align-items-center mt-3 mt-md-0">
          <button
            type="button"
            className="btn btn-primary w-full py-9px"
            style={{ minWidth: 313 }}
            onClick={showDeleteModal}
          >
            Delete Report
          </button>
        </div>
      </div>

      <div class="d-flex mt-3 mt-md-0" style={{ gap: 10 }}>
        <button
          type="button"
          className="btn btn-secondary w-full py-9px d-flex align-items-center"
          style={{ maxWidth: 100, gap: 10 }}
          onClick={exportExcel}
        >
          <img src={downloadIcon} alt="" />
          <span>EXCEL</span>
        </button>

        <button
          type="button"
          icon="pi pi-file-excel"
          className="btn btn-secondary w-full py-9px d-flex align-items-center"
          style={{ maxWidth: 100, gap: 10 }}
          onClick={exportPdf}
        >
          <img src={downloadIcon} alt="" />
          <span>PDF</span>
        </button>
      </div>
    </>
  );

  const exceptionHeader = (
    <>
      <div
        class="d-flex justify-content-md-between justify-content-center 
        algin-items-center flex-wrap text-center text-md-left"
        style={{ marginTop: 1.75, marginBottom: 1.75 }}
      >
        <div class="d-flex flex-column">
          <h2 class="report-title text-left mb-2">Exception Report</h2>

          <p class="report-subtext">
            Overs: {exceptDetails.except_num_of_overs}{" "}
            <i className="pi pi-info-circle" title="Vehicles missing from DMS Report" style={{'fontSize': '1em'}}></i>
          </p>

          <p class="report-subtext b-subtext">
          Unders:{" "}{exceptDetails.except_num_of_unders}{" "}
          <i className="pi pi-info-circle" title="Vehicles missing from the Count Report" style={{'fontSize': '1em'}}></i>
          </p>

          <p class="report-subtext b-subtext">
            Total: {exceptDetails.except_num_of_total}
          </p>
        </div>

        <div class="d-flex align-items-center mt-3 mt-md-0">
          <button
            type="button"
            className="btn btn-primary w-full py-9px"
            style={{ minWidth: 313 }}
            onClick={showExceptDeleteModal}
          >
            Delete Report
          </button>
        </div>
      </div>

      <div class="d-flex mt-3 mt-md-0" style={{ gap: 10 }}>
        <button
          type="button"
          className="btn btn-secondary w-full py-9px d-flex align-items-center"
          style={{ maxWidth: 100, gap: 10 }}
          onClick={exportExceptExcel}
        >
          <img src={downloadIcon} alt="" />
          <span>EXCEL</span>
        </button>

        <button
          type="button"
          icon="pi pi-file-excel"
          className="btn btn-secondary w-full py-9px d-flex align-items-center"
          style={{ maxWidth: 100, gap: 10 }}
          onClick={exportExceptPdf}
        >
          <img src={downloadIcon} alt="" />
          <span>PDF</span>
        </button>
      </div>
    </>
  );

  const mainHeader = (
    <>
      <div
        class="d-flex justify-content-md-between justify-content-center 
        algin-items-center flex-wrap text-center text-md-left physical-count-datatable"
      >
        <div class="d-flex flex-column">
          <h2 class="report-title text-left mb-2">Conslidated Count</h2>
          <p class="report-subtext">
            Use the tabs to switch between Conslidated and Exception reports.
          </p>
        </div>

        <div class="d-flex align-items-center mt-3 mt-md-0">
          <div
            class="form-group has-search w-100 mb-0"
            style={{ maxWidth: 373 }}
          >
            <img src={searchIcon} class="form-control-feedback" alt="" />
            <input
              type="text"
              class="form-control form-search"
              placeholder="Search..."
              onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <Container>
      {mainHeader}

      <div className="physical-count-datatable">
        <div id="countTables">
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header="Count">
              <DataTable
                id="countTable"
                value={lots}
                paginator
                rows={10}
                header={countHeader}
                globalFilter={globalFilter}
                emptyMessage={emptyCountMessage}
              >
                {countCols}
              </DataTable>
            </TabPanel>
            <TabPanel header="Exception">
              <DataTable
                id="exceptionTable"
                value={exceptVins}
                paginator
                rows={25}
                header={exceptionHeader}
                globalFilter={globalFilter}
                emptyMessage={emptyExceptionsMessage}
              >
                <Column field="vin" header="VIN" sortable></Column>
                <Column field="user" header="User" sortable></Column>
                <Column
                  field="isOver"
                  header="Over"
                  body={showIsOver}
                  sortable
                ></Column>
                <Column
                  field="locationOver"
                  header="Over Location"
                  sortable
                ></Column>
                <Column
                  field="isUnder"
                  header="Under"
                  body={showIsUnder}
                  sortable
                ></Column>
                <Column
                  field="locationUnder"
                  header="Under Location"
                  sortable
                ></Column>
                {/* {exceptColumns} */}
              </DataTable>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </Container>
  );
}

export default Count;
