import React, { useEffect, useState, useContext } from "react";
import axios from "axios";

import { AuthContext } from "../contexts/Auth";
import { Container } from "react-bootstrap";

import { DoubleBubble } from "react-spinner-animated";
import "react-spinner-animated/dist/index.css";

import { db } from "../Firebase.js";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";

import { Button } from "primereact/button";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../styles/inventory.css";

import downloadIcon from "../images/icons/icon-upload-primary.svg";
import searchIcon from "../images/icons/icon-search-primary.svg";


const TekionAPI = () => {
  const [inventoryData, setInvetoryData] = useState(null);
  const [accesskey, setAccesskey] = useState("");

  const columns = [
    { field: "make", header: "Make" },
    { field: "model", header: "Model" },
    { field: "year", header: "Year" },
    { field: "colour", header: "Colour" },
    { field: "trim", header: "Trim" },
    { field: 'cost', header: 'Cost'},
  ];


  const PDFcolumns = [
    { field: "vin", header: "VIN" },
    { field: "make", header: "Make" },
    { field: "model", header: "Model" },
    { field: "year", header: "Year" },
    { field: "colour", header: "Colour" },
    { field: "trim", header: "Trim" },
  ];

  const exportColumns = PDFcolumns.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const [globalFilter, setGlobalFilter] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState(columns);

  const [fetchDB, setFetchDB] = useState(true);
  const [loadingText, setLoadingText] = useState("");

  const { userInfo } = useContext(AuthContext);

  useEffect(() => {
    setFetchDB(true);
    if (userInfo !== undefined) {

      checkDBInventory();
      // fetchData();
    }
  }, []);

  if (fetchDB) {
    return (
      <DoubleBubble
        text={loadingText}
        center={true}
        width={"150px"}
        height={"150px"}
      />
    );
  }

  async function checkDBInventory(key) {
    const docRef = doc(db, "orgs", userInfo.org);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      if (docSnap.data().hasInventory) {
        console.log("Inventory Exists");
        fetchInventoryFromDB();
      } else {
        console.log("No Inventory");
        fetchData();
      }
    }
  }

  async function fetchData() {
    const qs = require("querystring");
    const data = {
      "access-key": "f9be3b29eda3490f9fa969a39aad2f2c",
      "secret-key": "$Zh!06LYul5vbHsL",
    };

    const options = {
      method: "POST",
      headers: {
        client_id: "6013ca1b80964f7b9b147e23f2f1a46e",
        "content-type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(data),
      url: "https://api.tekioncloud.xyz/auth/v1/oauth2/token",
    };

    axios
      .request(options)
      .then(function (res) {
        // fetchVehicleInventory(res.data.access_token);
        var items = [];
        var nextPageKey = "";

        getAllInventoryFromAPI(res.data.access_token, nextPageKey, items);
      })
      .catch(function (err) {
        console.log("error = " + err);
      });
  }

  async function writeInventoryToDB(inventory_arr) {
    const db_arr = [];
    var current_cost = 0;

    const orgsInventoryRef = collection(
      db,
      "orgs",
      userInfo.org,
      "inventoryReport"
    );

    const batch = writeBatch(db);

    inventory_arr.forEach(function (inventoryItem) {
      var currentCost = 0; 

      var lastSixVIN = inventoryItem.vin.slice(-6);

      var orgsInventoryVehicleRef = doc(
        db,
        "orgs",
        userInfo.org,
        "inventoryReport",
        lastSixVIN
      );

      // currentCost = inventoryItem.pricingDetails.msrp.amount
      // currentCost = inventoryItem.pricingDetails.retailPrice.amount
      if(inventoryItem.pricingDetails.invoicePrice != null) {
        currentCost = inventoryItem.pricingDetails.invoicePrice.amount;
      }

      var dbVIN = {
        vin: lastSixVIN,
        fullVIN: inventoryItem.vin,
        stockNo: inventoryItem.stockId,
        make: inventoryItem.make,
        model: inventoryItem.model,
        year: inventoryItem.year,
        colour: inventoryItem.exteriorColor,
        trim: inventoryItem.trimDetails.trim,
        cost: currentCost
      };

      db_arr.push(dbVIN);

      batch.set(orgsInventoryVehicleRef, dbVIN, { merge: true });
    });

    const time = getTodaysTime();
    const date = getTodaysDate();

    const orgDetailsRef = doc(db, "orgs", userInfo.org);
    batch.update(orgDetailsRef, {
      hasInventory: true,
      inventoryTime: time,
      inventoryDate: date,
      inventorySize: inventory_arr.length,
    });

    console.log("writing to DB");
    await batch.commit().then(function () {
      console.log("Done Uploading Inventory!");
      setInvetoryData(db_arr);
      setFetchDB(false);
    });
  }

  async function fetchInventoryFromDB() {
    const items = [];

    const docRef = collection(db, "orgs", userInfo.org, "inventoryReport");
    const querySnapshot = await getDocs(docRef);

    querySnapshot.forEach((doc) => {
      items.push(doc.data());
    });

    setInvetoryData(items);
    setFetchDB(false);
    console.log("THIS IS FROM DATABASE");
  }

  // async function fetchVehicleInventory(key) {
  //   const options = {
  //     method: "GET",
  //     headers: {
  //       client_id: "6013ca1b80964f7b9b147e23f2f1a46e",
  //       dealerid: "techmotors_4",
  //       "content-type": "application/json",
  //       Authorization: "Bearer " + key,
  //     },
  //     params: {
  //       make: "Honda",
  //     },
  //     url: "https://api.tekioncloud.xyz/api/v2/vehicle/inventory",
  //   };

  //   axios
  //     .request(options)
  //     .then(function (res) {
  //       writeInventoryToDB(res.data.data);
  //       // printData(res.data.meta);
  //       // setFetchDB(false);
  //     })
  //     .catch(function (err) {
  //       console.log("error = " + err.message);
  //     });
  // }

  async function getAllInventoryFromAPI(key, nextPageKey, items) {
    var tekionURL = "";

    if (nextPageKey) {
      tekionURL =
        "https://api.tekioncloud.xyz/api/v2/vehicle/inventory?nextPageKey=" +
        nextPageKey;
    } else {
      tekionURL = "https://api.tekioncloud.xyz/api/v2/vehicle/inventory";
    }

    const options = {
      method: "GET",
      headers: {
        client_id: "6013ca1b80964f7b9b147e23f2f1a46e",
        dealerid: "techmotors_4",
        "content-type": "application/json",
        Authorization: "Bearer " + key,
      },
      params: {
        make: "Honda",
      },
      url: tekionURL,
    };

    axios
      .request(options)
      .then(function (res) {
        var totalPages = res.data.meta.total_pages;
        var currentPage = res.data.meta.current_page;
        var nextPageKey = res.data.meta.nextPageKey;

        console.log("---results---");
        items.push(res.data.data);
        console.log(res.data.data);
        if (currentPage < totalPages) {
          console.log("currentPage = " + currentPage + " / " + totalPages);
          getAllInventoryFromAPI(key, nextPageKey, items);
        } else {
          const bigData = items.flat(1);

          // setInvetoryData(bigData);
          console.log("Completed API Call!");
          // setFetchDB(false);
          writeInventoryToDB(bigData);
          console.log("Full API List Completed.");
        }
      })
      .catch((error) => console.log("ERROR: " + error));
  }

  function printData(meta) {
    console.log("Count: " + meta.total_count);
    console.log("NextPageKey: " + meta.nextPageKey);
    console.log("Current Page: " + meta.current_page);
    console.log("Total Pages: " + meta.total_pages);
    console.log(meta);
  }

  function getTodaysDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    return dd + "-" + mm + "-" + yyyy;
  }

  function getTodaysTime() {
    var time = new Date();
    var hh = String(time.getHours()).padStart(2, "0");
    var mm = String(time.getMinutes()).padStart(2, "0");
    return hh + ":" + mm;
  }

  const exportPdf = () => {
    const newDate = getTodaysDate();
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, inventoryData);
        doc.save("Inventory_" + newDate + ".pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(inventoryData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Inventory");
    });
  };

  function saveAsExcelFile(buffer, fileName) {
    const newDate = getTodaysDate();
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(data, fileName + "_" + newDate + EXCEL_EXTENSION);
    });
  }

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );

    setVisibleColumns(orderedSelectedColumns);
  };

  const header = (
    <MultiSelect
      value={visibleColumns}
      options={columns}
      optionLabel="header"
      onChange={onColumnToggle}
      className="w-full sm:w-20rem"
      display="chip"
    />
  );

  return (
    <div>
      <Container className="inventory-data">
        <div
          class="d-flex justify-content-md-between justify-content-center 
          algin-items-center flex-wrap text-center text-md-left"
          style={{ marginTop: 1.75 }}
        >
          <div class="d-flex flex-column">
            <h2 class="report-title text-left mb-2">Inventory</h2>
            <p class="report-subtext">
              Please remember to refresh the inventory regularly for the most up
              to date results.
            </p>
          </div>

          <div class="d-flex align-items-center mt-3 mt-md-0">
            <Button
              type="button"
              className="btn btn-primary w-full py-9px"
              style={{ minWidth: 313 }}
              icon="pi pi-refresh"
              label="Refresh Inventory"
              tooltip="This may take a few minutes." 
              tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
              // onClick={}
            >
            </Button>
          </div>
        </div>

        <div class="d-flex justify-content-md-between justify-content-center algin-items-center flex-wrap mt-3">
          <div
            class="form-group has-search w-100 mb-0"
            style={{ maxWidth: 373 }}
          >
            <img src={searchIcon} class="form-control-feedback" alt="" />
            <input
              type="text"
              class="form-control form-search"
              placeholder="Search..."
              onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </div>

          <div class="d-flex mt-3 mt-md-0" style={{ gap: 10 }}>
            <button
              type="button"
              className="btn btn-secondary w-full py-9px d-flex align-items-center"
              style={{ maxWidth: 100, gap: 10 }}
              onClick={exportExcel}
            >
              <img src={downloadIcon} alt="" />
              <span>EXCEL</span>
            </button>

            <button
              type="button"
              icon="pi pi-file-excel"
              className="btn btn-secondary w-full py-9px d-flex align-items-center"
              style={{ maxWidth: 100, gap: 10 }}
              onClick={exportPdf}
            >
              <img src={downloadIcon} alt="" />
              <span>PDF</span>
            </button>
          </div>
        </div>

        <div>
          <DataTable
            id="inventoryTable"
            value={inventoryData}
            header={header}
            paginator
            removableSort
            reorderableColumns
            globalFilter={globalFilter}
            rows={50}
          >
            <Column field="vin" header="VIN" sortable></Column>

            {visibleColumns.map((col) => (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                sortable
              ></Column>
            ))}
          </DataTable>
        </div>
      </Container>
    </div>
  );
};

export default TekionAPI;
