import { getDocs, collection } from "firebase/firestore";
import React, { useEffect, useState, useContext } from "react";
import { Container, Button, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { db } from "../Firebase.js";
import { AuthContext } from "../contexts/Auth";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import "../styles/count.css";

import { DoubleBubble } from "react-spinner-animated";
import "react-spinner-animated/dist/index.css";

function Reports() {
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);

  const [fetchDB, setFetchDB] = useState(true);
  const [lots, setLots] = useState([]);

  const [showAllDeleteWarning, setShowAllDeleteWarning] = useState(false);
  const handleDeleteWarningClose = () => setShowAllDeleteWarning(false);

  useEffect(() => {
    setFetchDB(true);
    if (userInfo !== undefined) {
      getLots();
    }
  }, [userInfo]);

  async function getLots() {
    const items = [];

    const docRef = collection(db, "orgs", userInfo.org, "lots");
    const querySnapshot = await getDocs(docRef);

    querySnapshot.forEach((doc) => {
      items.push(doc.data());
    });
    console.log("lots");
    setFetchDB(false);
    setLots(items);
    console.log(items);
  }

  const emptyMessage = (
    <div className="md-4 text-center">
      <h4>Error loading locations</h4>
    </div>
  );

  const header = ( 
    <>
    <div class="d-flex justify-content-md-between justify-content-center 
      algin-items-center flex-wrap text-center text-md-left" style={{marginTop: 1.75}}>

    <div class="d-flex flex-column">
      <h2 class="report-title text-left mb-2">Physical Count Reports</h2>
      <p class="report-subtext">Click view to see the Count or Issue Report for the location</p>
    </div>
    
    {/* <div class="d-flex align-items-center mt-3 mt-md-0">
      <button
        type="button"
        className="btn btn-primary w-full py-9px"
        style={{ minWidth: 313 }}
        onClick={showDeleteAllModal}
        >
        Clear All Reports{" "}
      </button>
    </div> */}
    </div>
    </>
  );

  const showDeleteAllModal = () => {
    setShowAllDeleteWarning(true);
  }

  if(showAllDeleteWarning) {

  }

  const openReport = (autogroup) => {
    history.push("count_report/" + autogroup);
  };

  const viewReportBody = (rowData) => {
    return (
      <Button
        type="button"
        disabled={!rowData.countCompleted}
        onClick={() => history.push("count_report/" + rowData.name)}
      >
        View
      </Button>
    );
  };

  const viewIssueReportBody = (rowData) => {
    return (
      <Button
        type="button"
        disabled={!rowData.has_issues}
        onClick={() => history.push("issue_report/" + rowData.name)}
      >
        View
      </Button>
    );
  }

  const viewCountCompleted = (rowData) => {
    const isCountCompleted = rowData.countCompleted;
    if (isCountCompleted) {
      // return <i className="pi pi-check-square" style={{ fontSize: "2em" }}></i>;
      return <Image src="/check_mark.png" alt={"tapngo"} width="56" height="56" className="center" />
    } else {
      return <Image src="/cancel.png" alt={"tapngo"} width="56" height="56" className="center" />
    }
  };

  const showExceptionCompleted = (rowData) => {
    const isExceptioncompleted = rowData.exceptionCompleted;

    if(isExceptioncompleted) {
      return <Image src="/check_mark.png" alt={"tapngo"} width="56" height="56" className="center" />
    } else {
      return <Image src="/cancel.png" alt={"tapngo"} width="56" height="56" className="center" />
    }
  }

  if (fetchDB) {
    return (
      <DoubleBubble
        text={"Loading..."}
        center={true}
        width={"150px"}
        height={"150px"}
      />
    );
  }


  return (
    <Container>
      <div className="physical-count-datatable">

        {header}

        <DataTable
          // id="countTable"
          value={lots}
          paginator
          rows={10}
          // header={header}
          emptyMessage={emptyMessage}
          className="table table-report table-bordered mt-4"
        >
          <Column field="name" header="Location" sortable></Column>
          <Column
            field="pc_date_updated"
            header="Date Updated"
            sortable
          ></Column>
          <Column
            field="pc_time_updated"
            header="Time Updated"
            sortable
          ></Column>
          <Column
            field="pc_num_of_count"
            header="Total Count"
            sortable
          ></Column>
          <Column
            field="isCountCompleted"
            header="Count Report"
            body={viewReportBody}
          ></Column>
          <Column
            field="isCountCompleted"
            // field="hasIssues"
            header="Issue Report"
            body={viewIssueReportBody}
          ></Column>
          {/* <Column
            field="countCompleted"
            header="Count Completed"
            body={viewCountCompleted}
            sortable
          ></Column> */}
          <Column 
          field="exceptionCompleted"
          header="Exception Report"
          body={showExceptionCompleted}
          sortable
          ></Column>
        </DataTable>
      </div>
    </Container>
  );
}

export default Reports;
