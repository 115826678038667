import React from "react";
import "../styles/loading.css";
import ReactLoading from 'react-loading';
import { Container, Row } from "react-bootstrap";


function Loading(props) {
    return (
        <Container>
            <Row className="center-screen">
            <ReactLoading type="cylon" color={"#8F00FF"} height={'20%'} width={'20%'} />
            </Row>
            <Row className="center-screen">
            <div id="app_name"> <h3>{props.message}</h3> </div>
            </Row>
        </Container>
    );
}


export default Loading; 