// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import 'firebase/compat/firestore';
import 'firebase/storage';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCzgvHBUyGAWT1_LU2TNq65uaSTXj6Hr3Y",
  authDomain: "tapngo-29713.firebaseapp.com",
  databaseURL: "https://tapngo-29713.firebaseio.com",
  projectId: "tapngo-29713",
  storageBucket: "tapngo-29713.appspot.com",
  messagingSenderId: "900147843650",
  appId: "1:900147843650:web:38d6221e73e2fb789053db"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app 
