import React, { useEffect, useState } from "react";
import { auth, db } from "../Firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import Loading from "../components/Loading";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userInfo, setUserInfo] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if(user) {
        getUser(user.uid);
      }else {
        setPending(false);
      }
    });
  }, []);

  async function getUser(uid) {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUserInfo(docSnap.data());
      setPending(false);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such User!");
      setPending(false);
    }
  }

  if (pending) {
    return <Loading message="Retrieving User Information..."/>
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userInfo
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
